<template>
  <div class="search-result widget">
    <label v-if="title" class="label">
      {{ title }}
    </label>

    <div class="card pa">
      <!-- Loader -->
      <spinner
        v-if="loading && !meta.current_page"
        center
        large
        class="mt-15"
      />

      <!-- Items -->
      <div class="items">
        <div v-for="item in items" :key="item.id" class="item">
          <slot name="item" v-bind="{ item }"> </slot>
        </div>
      </div>

      <!-- No data -->
      <v-alert v-if="!loading && !data.length" color="info" text class="mb-0">
        {{ $t("common.noDataFound") }}
      </v-alert>

      <!-- Button -->
      <div class="d-flex justify-center">
        <!-- For global search -->
        <v-btn
          v-if="isGlobalSearch && data.length > 3"
          color="primary"
          small
          rounded
          class="mt-5"
          :to="{
            name: 'app.search',
            params: { index },
            query: { q: $route.query.q },
          }"
        >
          {{ $t("common.showAll") }}
        </v-btn>

        <!-- For index search -->
        <v-btn
          v-else-if="isIndexSearch && $utils.canLoadMore(meta)"
          color="primary"
          small
          rounded
          class="mt-5"
          :loading="loading"
          @click="fetchData"
        >
          {{ $t("common.loadMore") }}
        </v-btn>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    index: String,
    title: String,
    result: Array,
  },

  data: () => ({
    data: [],
    meta: {
      current_page: 0,
    },
    loading: false,
  }),

  computed: {
    isGlobalSearch() {
      return !this.$route.params.index;
    },

    isIndexSearch() {
      return !this.isGlobalSearch;
    },

    items() {
      return this.isGlobalSearch ? this.data.slice(0, 3) : this.data;
    },
  },

  watch: {
    "$route.query.q": function () {
      this.fetchData();
    },
  },

  created() {
    if (this.result) {
      this.data = this.result;
    }

    if (this.isIndexSearch) {
      this.fetchData();
    }
  },

  methods: {
    async fetchData() {
      try {
        this.loading = true;

        const res = await this.$store.dispatch("search/index", {
          index: this.index,
          params: {
            query: this.$route.query.q,
            per_page: 10,
            page: this.meta.current_page + 1,
          },
        });

        this.meta = res.meta;
        this.data.push(...res.data);
      } catch (e) {
        this.$toast.error("Error while searching.");
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>

<style lang="scss">
.item {
  &:not(:last-of-type) {
    margin-bottom: 20px;
  }
}
</style>
