<template>
  <column-layout class="page settings-page" sticky-sides no-right>
    <!-- Left -->
    <template v-slot:left>
      <SearchMenu />
    </template>

    <!-- Main -->
    <template v-slot>
      <!-- Loader -->
      <spinner v-if="loading" center large class="h-100" />

      <!-- Results -->
      <template v-else>
        <!-- Users -->
        <SearchResult
          v-if="!index || index === 'users'"
          index="users"
          :title="$t('common.users')"
          :result="results.users"
        >
          <template v-slot:item="{ item }">
            <UserRow :user="item" />
          </template>
        </SearchResult>

        <!-- Groups -->
        <SearchResult
          v-if="!index || index === 'groups'"
          index="groups"
          :title="$t('common.groups')"
          :result="results.groups"
          :class="{ 'my-10': !index }"
        >
          <template v-slot:item="{ item }">
            <GroupRow :group="item" description />
          </template>
        </SearchResult>

        <!-- Posts -->
        <SearchResult
          v-if="!index || index === 'posts'"
          index="posts"
          :title="$t('posts.posts')"
          :result="results.posts"
        >
          <template v-slot:item="{ item }">
            <Post
              :post="item"
              hideComments
              class="ocean-dark pa-3 rounded-lg"
            />
          </template>
        </SearchResult>
      </template>
    </template>
  </column-layout>
</template>

<script>
import GroupRow from "@/components/app/groups/GroupRow.vue";
import Post from "@/components/app/posts/Post.vue";
import SearchMenu from "@/components/app/search/SearchMenu.vue";
import SearchResult from "@/components/app/search/SearchResult.vue";
import UserRow from "@/components/app/users/UserRow.vue";

export default {
  metaInfo: { title: "Search" },

  components: {
    SearchMenu,
    SearchResult,
    UserRow,
    GroupRow,
    Post,
  },

  data: () => ({
    loading: false,
    results: {
      users: [],
      groups: [],
      posts: [],
    },
  }),

  computed: {
    index() {
      return this.$route.params.index;
    },
  },

  watch: {
    "$route.query.q": "fetchData",
  },

  created() {
    if (this.validateIndex()) {
      !this.index && this.fetchData();
    }
  },

  methods: {
    async fetchData() {
      if (this.index) {
        return;
      }

      try {
        this.loading = true;

        const { data } = await this.$store.dispatch("search/global", {
          query: this.$route.query.q,
          per_page: 4,
        });

        this.results = data;
      } catch (e) {
        this.$toast.error("Error while searching.");
      } finally {
        this.loading = false;
      }
    },

    validateIndex() {
      const allowed = [undefined, "users", "groups", "posts"];

      if (!allowed.includes(this.index)) {
        this.$utils.handleError({ status: 404 });
      }

      return true;
    },
  },
};
</script>
