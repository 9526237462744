<template>
  <div class="search-menu tabs-menu widget">
    <label class="label">
      {{ $t("common.type") }}
    </label>

    <v-tabs vertical class="card py-4">
      <v-tab
        v-for="tab in tabs"
        :key="tab.text"
        :to="tab.to"
        style="justify-content: left; color: white; max-width: unset"
      >
        <v-icon class="mr-4" color="white" size="26">{{ tab.icon }}</v-icon>
        {{ tab.text }}
      </v-tab>
    </v-tabs>
  </div>
</template>

<script>
export default {
  data: function () {
    return {
      loading: false,
      tabs: [
        {
          text: this.$t("common.users"),
          icon: "mdi-account",
          to: {
            name: "app.search",
            params: { index: "users" },
            query: { q: this.$route.query.q },
          },
        },
        {
          text: this.$t("common.groups"),
          icon: "mdi-account-group",
          to: {
            name: "app.search",
            params: { index: "groups" },
            query: { q: this.$route.query.q },
          },
        },
        {
          text: this.$t("posts.posts"),
          icon: "mdi-card-text-outline",
          to: {
            name: "app.search",
            params: { index: "posts" },
            query: { q: this.$route.query.q },
          },
        },
      ],
    };
  },
};
</script>
